import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/a-guide-to-the-unique-homes-in-university-heights";

  return (
    <LayoutWrapper
      title="A Guide to the Unique Homes in University Heights"
      desc="Explore the charm of the houses in University Heights, CA with our comprehensive guide. Learn why this is one of San Diego's trendiest neighborhoods!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              A Guide to the Unique Homes in University Heights
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Nov 20, 2023</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_A Guide To The Unique Homes In University Heights.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                San Diego is home to various distinctive and interesting residential neighborhoods.
                Whether you prefer to be closer to the beach, in the heart of downtown, or in an
                area that mixes both, you’re sure to find a San Diego neighborhood that fulfills all
                your preferences and then some.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you want to move to one of San Diego’s trendiest neighborhoods, don’t hesitate to
                check out{" "}
                <a
                  href="https://selbysellssd.com/san-diego-communities/university-heights-homes-for-sale"
                  target="_Blank"
                  className="text-blue-500"
                >
                  University Heights
                </a>
                . Our agents at the Selby Team are excited about all the great homes available in
                this up-and-coming area. Here’s a guide to finding unique homes in University
                Heights, San Diego, courtesy of the Selby Team.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                What Is University Heights?
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                University Heights is nestled directly north of Balboa Park in central San Diego.
                Despite its misleading title, this neighborhood has no university or college campus,
                so non-students don’t have to worry about school traffic or noise.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Just above the neighborhood is a long stretch of Interstate 8, which extends east to
                west across the city. I-8 also connects to I-5 to the west, which travels north to
                Los Angeles or south to Chula Vista. The San Diego International Airport is less
                than 10 miles from the University Heights neighborhood.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                This unique area features a variety of home types, including single-family
                dwellings, apartments, townhomes, and condos. There’s something available in
                University Heights for most budgets, depending on which area of the neighborhood
                you’re browsing in.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                An Eclectic Neighborhood for All Lifestyles
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                People of all ages, lifestyles, and interests will find activities to enjoy in and
                around University Heights. This neighborhood is convenient to many of San Diego’s
                most active business districts, which means you can easily commute to work
                throughout the city by car or public transit.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                University Heights is known for its many coffee shops with cozy, inviting vibes —
                perfect for working remotely or meeting up with friends. There are also all kinds of
                unique and tasty restaurants and locally-owned shops and boutiques nearby. Much of
                the neighborhood’s shopping can be done on Park Boulevard, one of University
                Heights’ most central thoroughfares.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Outdoorsy types will have no problem finding plenty of public green spaces in and
                around University Heights. Only 1.5 miles from the neighborhood sits the expansive
                Balboa Park, home to the San Diego Zoo, the Japanese Friendship Garden and Museum,
                and the San Diego Natural History Museum. It also hosts other amenities like sports
                courts, walking and hiking trails, and open fields.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Convenient Access to Amenities
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Finding your next favorite restaurant, bar, coffee shop, or boutique is easy for
                residents of University Heights. However, the essentials are just as easily within
                reach.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Popular grocers like Ralphs, Sprouts, and Trader Joe’s have locations within
                University Heights or just outside it. Other necessities like drug stores, pet
                supply stores, and department stores are available to University Heights residents
                within just a few minutes of driving or walking.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Achieve Your Homeownership Dreams With the Selby Team
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                At Selby, we’re passionate about connecting potential San Diego homeowners with
                their dream property. <ContactSlideoutLink text="Contact The Selby Team" /> to get
                started on finding your new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
